.changelog {
    padding: 10px;
    background-color: #e7d8b8;
    border-radius: 5px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .changelog h2 {
    font-size: 1rem;
  }

  .changelog h3 {
    font-size: 1rem;
    margin-bottom: -10px;
  }
  .changelog h4 {
    font-size: 0.8rem;
    margin-bottom: -10px;
  }
  
  .changelog ul {
    font-size: 1rem;
    list-style-type: none;
    padding: 0;
  }
  
  .changelog li {
    font-size: 1rem ;
    margin-bottom: 10px;
  }