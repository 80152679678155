/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  background-color: #F0EAD6;
  font-family: 'EB Garamond', 'serif';
}

.title {
  font-size: 2.5rem;
  font-family: 'Parisienne', serif;
  margin-left: 10px;
  margin-top: -0px;
  color: #252525
}

.sub-header {
  font-family: 'EB Garamond', serif;
  font-size: 1rem;
  color: #414141;
  margin-top: -30px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.title-div{
  background-color: #F0EAD6;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.logo-image {
  float: left;
  width: 55px;
  height: auto;
  margin-right: 10px;
  background-color: inherit;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 0px 250px;
  background-color: #D9C9A7

}

@media (max-width: 600px) {
  .nav-bar {
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }
}


.nav-bar a {
  text-decoration: none;
  color: rgb(39, 8, 8);
  padding: 10px; /* Adjust padding as needed */
}

.nav-bar a:hover {
  background-color: #A99985
}

