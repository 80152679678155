.opus-box {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 50px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #dbc6a2;
    transition: all 0.3s ease;
    width: 300px;
  }
  
  .opus-box.expanded {
    width: 70%;
  }

  .opus-box:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .opus-box p {
    margin: 10px 0;
  }
  
  .opus-box .title {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .opus-box .details {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .opus-box button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #91805c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .opus-box button:hover {
    background-color: #61563e;
  }

  .medal-image {
    margin-left: 10px;
    width: 15px;
    height: auto;
    transform: translateY(5px);
  }